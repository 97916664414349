$rich: #00171fff;
$prussian: #004e68;
$celadon: #007ea7ff;
$carolina: #00a8e8ff;
$font: #ffffffff;
$purple: #F7D91A;
$violet: #A68200;

.stats-display {
    width: 100%;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background-color: $prussian;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-top: -2em;
    margin-bottom: -2em;
    z-index: -33;
}
.stat-block
{
    min-width: 25vw;
    padding: 2em;
    text-align: center;
    font-weight: bolder;
    p:first-child
    {
        font-family: monospace;
        font-size: 6em;
        color: $purple;
        text-shadow: 0px 0px 19px $purple;
    }
    p:last-child
    {
        font-family: monospace;
        font-size: 2em;
        text-shadow: 0px 0px 19px $font;
    }
}

@media only screen and (max-width: 1224px) {
    .stats-display
    {
        margin-top: 1em;
        p:first-child
        {
            font-family: monospace;
            font-size: 4em;
            color: $purple;
            text-shadow: 0px 0px 19px $purple;
        }
        p:last-child
        {
            font-family: monospace;
            font-size: 2em;
            text-shadow: 0px 0px 19px $font;
        }
    }
}

