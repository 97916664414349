footer {
  background-color: #00171f;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  box-shadow: 0px -8px 24px 0px rgb(0, 23, 31);
  border-radius: 1em 1em 0% 0%;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
}
footer p {
  margin-bottom: 1em;
  width: 100%;
  text-align: center;
}
footer .socials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}
footer .socials .btn {
  margin-left: 2em;
  margin-right: 2em;
  text-decoration: none;
  text-align: center;
  color: #00a8e8;
  font-weight: bolder;
  font-size: 1.25em;
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #00a8e8;
}
footer .socials .btn .awesome-icon {
  filter: drop-shadow(0px 0px 10px #00a8e8);
  text-decoration: none;
  text-align: center;
  color: #00a8e8;
  font-weight: bolder;
  font-size: 1.25em;
  transition: 0.25s ease;
}
footer .socials .btn img {
  filter: drop-shadow(0px 0px 5px #00a8e8);
}
footer .socials .btn:hover {
  transition: 0.15s ease;
  color: #f7d91a;
  transform: scale(1.1);
  text-shadow: 0px 0px 19px #f7d91a;
}
footer .socials .btn:hover .awesome-icon {
  filter: drop-shadow(0px 0px 19px #f7d91a);
  transition: 0.15s ease;
  color: #f7d91a;
  transform: scale(1.1);
}
footer .socials .btn:hover img {
  transition: 0.15s ease;
  filter: hue-rotate(90deg) drop-shadow(0px 0px 10px #f7d91a);
}/*# sourceMappingURL=footer.css.map */