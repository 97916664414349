$rich: #00171fff;
$prussian: #003459ff;
$celadon: #007ea7ff;
$carolina: #00a8e8ff;
$font: #ffffffff;
$yellow: #f7d91a;
$gold: #a68200;


footer {
    background-color: $rich;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    -webkit-box-shadow: 0px -8px 24px 0px rgba(0, 23, 31, 1);
    -moz-box-shadow: 0px -8px 24px 0px rgba(0, 23, 31, 1);
    box-shadow: 0px -8px 24px 0px rgba(0, 23, 31, 1);
    border-radius: 1em 1em 0% 0%;
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
    p {
        margin-bottom: 1em;
        width: 100%;
        text-align: center;
    }
    .socials {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        .btn {
            margin-left: 2em;
            margin-right: 2em;
            text-decoration: none;
            text-align: center;
            color: $carolina;
            font-weight: bolder;
            font-size: 1.25em;
            transition: 0.25s ease;
            text-shadow: 0px 0px 10px $carolina;
            .awesome-icon {
                filter: drop-shadow(0px 0px 10px $carolina);
                text-decoration: none;
                text-align: center;
                color: $carolina;
                font-weight: bolder;
                font-size: 1.25em;
                transition: 0.25s ease;
            }
            img {
                filter: drop-shadow(0px 0px 5px $carolina);
            }
        }
        .btn:hover {
            transition: 0.15s ease;
            color: $yellow;
            transform: scale(1.1);
            text-shadow: 0px 0px 19px $yellow;
            .awesome-icon {
                filter: drop-shadow(0px 0px 19px $yellow);
                transition: 0.15s ease;
                color: $yellow;
                transform: scale(1.1);
            }
            img {
                transition: 0.15s ease;
                filter: hue-rotate(90deg) drop-shadow(0px 0px 10px $yellow);
            }
        }
    }
}