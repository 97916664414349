@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$rich: #00171fff;
$prussian: #003459ff;
$celadon: #007ea7ff;
$carolina: #00a8e8ff;
$font: #ffffffff;
$yellow: #F7D91A;
$gold: #A68200;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    min-height: 100vh;
    max-width: 100%;
    background-color: #004e68;
    color: $font;
}

.page
{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $yellow $rich;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: $rich;
}

*::-webkit-scrollbar-thumb {
    background-color: $yellow;
    border-radius: 8px;
    border: 0px solid #ffffff;
}