$rich: #00171fff;
$prussian: #003459ff;
$celadon: #007ea7ff;
$carolina: #00a8e8ff;
$font: #ffffffff;
$yellow: #F7D91A;
$gold: #A68200;

.unconnected
{
  justify-content: center;
  h1
  {
    font-size: 3em;
    font-weight: bold;
    margin: 0.5em;
    margin-bottom: 1em;
    color: $yellow;
    text-shadow: $yellow 0px 0px 0.5em;
    text-align: center;
  }
}

main
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 70vh;
}

.interaction-window
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 2em;
    padding: 2em;
    margin: 1em;
    background-color: $rich;
    border-width: 0.25em;
    border-style: solid;
    border-color: $prussian;
    box-shadow: 8px 8px 24px 0px rgb(0, 0, 0);
    max-width: 50em;
    height: fit-content;
    width: 90%;

    .slider
    {
        max-width: 45em;
        margin-bottom: 2em !important;
    }

    input[type=range].slider {
        width: 100%;
        margin: 4.6px 0;
        background-color: transparent;
        -webkit-appearance: none;
      }
      input[type=range].slider:focus {
        outline: none;
      }
      input[type=range].slider::-webkit-slider-runnable-track {
        background: $celadon;
        border: 3px solid $prussian;
        border-radius: 11px;
        width: 100%;
        height: 5.8px;
        cursor: pointer;
      }
      input[type=range].slider::-webkit-slider-thumb {
        margin-top: -7.6px;
        width: 10px;
        height: 15px;
        background: $gold;
        border: 3.1px solid $yellow;
        border-radius: 50px;
        cursor: pointer;
        -webkit-appearance: none;
      }
      input[type=range].slider:focus::-webkit-slider-runnable-track {
        background: $gold;
      }
      input[type=range].slider::-moz-range-track {
        background: $celadon;
        border: 3px solid $prussian;
        border-radius: 11px;
        width: 100%;
        height: 5.8px;
        cursor: pointer;
      }
      input[type=range].slider::-moz-range-thumb {
        width: 10px;
        height: 15px;
        background: $gold;
        border: 3.1px solid $yellow;
        border-radius: 50px;
        cursor: pointer;
      }
      input[type=range].slider::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 4.6px 0;
        color: transparent;
        width: 100%;
        height: 5.8px;
        cursor: pointer;
      }
      input[type=range].slider::-ms-fill-lower {
        background: $celadon;
        border: 3px solid $prussian;
        border-radius: 22px;
      }
      input[type=range].slider::-ms-fill-upper {
        background: $celadon;
        border: 3px solid $prussian;
        border-radius: 22px;
      }
      input[type=range].slider::-ms-thumb {
        width: 10px;
        height: 15px;
        background: $gold;
        border: 3.1px solid $yellow;
        border-radius: 50px;
        cursor: pointer;
        margin-top: 0px;
        /*Needed to keep the Edge thumb centred*/
      }
      input[type=range].slider:focus::-ms-fill-lower {
        background: $gold;
      }
      input[type=range].slider:focus::-ms-fill-upper {
        background: $gold;
      }
      /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
      how to remove the virtical space around the range input in IE*/
      @supports (-ms-ime-align:auto) {
        /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
        input[type=range].slider {
          margin: 0;
          /*Edge starts the margin from the thumb, not the track as other browsers do*/
        }
      }

             
      .num-box
      {
          font-weight: bold;
          font-size: 1em;
          border-style: solid;
          border-width: 0.15em;
          padding: 0.5em;
          margin: 0.5em;
          margin-bottom: 2em;
          border-radius: 0.25em;
          transition: 0.25s ease;
          box-shadow: 0px 0px 10px 0px $carolina;
                  background-color: $carolina;
                  border-color: $celadon;
                  filter: saturate(90%);
          color: $font !important;
          min-width: 1.5em;
          width: 90%;
          max-width: 40em;
      }

    hr
    {
        opacity: 0.5;
        color: $font;
        margin-bottom: 2.5em;            
        margin-top: 0.5em;
        width: 100%;
    }


    .details
    {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      p:first-child
      {
          text-align: left;
          font-weight: bold;
          margin: 0.5em;
      }
      p:last-child
      {
          text-align: right;
          font-weight: bold;
          margin: 0.5em;
      }
    }
    
    .option-select {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 0.5em;
        width: 100%;
        gap: 4em;
        margin-bottom: 0.5em;
        button:disabled {
            font-weight: bold;
            border-style: solid;
            border-width: 0.15em;
            border-radius: 0.25em;
            transition: 0.25s ease;
            color: $carolina !important;
            background-color: $yellow;
            border-color: $gold;
            box-shadow: 0px 0px 19px 0px $yellow;
            filter: saturate(1.2);
            transform: scale(1.2);
            cursor: default;
        }

    }

    button {
        font-weight: bold;
        font-size: 1.25em;
        border-style: solid;
        border-width: 0.15em;
        color: $yellow!important;
        min-width: 7.5em;
        padding: 0.25em;
        border-radius: 0.25em;
        transition: 0.25s ease;
        background-color: $carolina;
        border-color: $celadon;
        box-shadow: 0px 0px 10px 0px $carolina;
        cursor: pointer;
    }
    button:hover:enabled {
        color: $font !important;
        transition: 0.15s ease;
        transform: scale(1.1);
        color: $carolina !important;
        background-color: $yellow;
        border-color: $gold;
        box-shadow: 0px 0px 19px 0px $yellow;
    }

    button:disabled {
        font-weight: bold;
        border-style: solid;
        border-width: 0.15em;
        color: $font !important;
        border-radius: 0.25em;
        transition: 0.25s ease;
        filter: grayscale(70%) saturate(25%);
        transform: scale(1);
        cursor: default;
    }

    @media only screen and (max-width: 800px) {
        .option-select {
            flex-direction: column;
            gap: 1em;
            button {
                width: 70%;
            }
        }
    }
}