.unconnected {
  justify-content: center;
}
.unconnected h1 {
  font-size: 3em;
  font-weight: bold;
  margin: 0.5em;
  margin-bottom: 1em;
  color: #F7D91A;
  text-shadow: #F7D91A 0px 0px 0.5em;
  text-align: center;
}

main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 70vh;
}

.interaction-window {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-radius: 2em;
  padding: 2em;
  margin: 1em;
  background-color: #00171f;
  border-width: 0.25em;
  border-style: solid;
  border-color: #003459;
  box-shadow: 8px 8px 24px 0px rgb(0, 0, 0);
  max-width: 50em;
  height: -moz-fit-content;
  height: fit-content;
  width: 90%;
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
}
.interaction-window .slider {
  max-width: 45em;
  margin-bottom: 2em !important;
}
.interaction-window input[type=range].slider {
  width: 100%;
  margin: 4.6px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.interaction-window input[type=range].slider:focus {
  outline: none;
}
.interaction-window input[type=range].slider::-webkit-slider-runnable-track {
  background: #007ea7;
  border: 3px solid #003459;
  border-radius: 11px;
  width: 100%;
  height: 5.8px;
  cursor: pointer;
}
.interaction-window input[type=range].slider::-webkit-slider-thumb {
  margin-top: -7.6px;
  width: 10px;
  height: 15px;
  background: #A68200;
  border: 3.1px solid #F7D91A;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}
.interaction-window input[type=range].slider:focus::-webkit-slider-runnable-track {
  background: #A68200;
}
.interaction-window input[type=range].slider::-moz-range-track {
  background: #007ea7;
  border: 3px solid #003459;
  border-radius: 11px;
  width: 100%;
  height: 5.8px;
  cursor: pointer;
}
.interaction-window input[type=range].slider::-moz-range-thumb {
  width: 10px;
  height: 15px;
  background: #A68200;
  border: 3.1px solid #F7D91A;
  border-radius: 50px;
  cursor: pointer;
}
.interaction-window input[type=range].slider::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 4.6px 0;
  color: transparent;
  width: 100%;
  height: 5.8px;
  cursor: pointer;
}
.interaction-window input[type=range].slider::-ms-fill-lower {
  background: #007ea7;
  border: 3px solid #003459;
  border-radius: 22px;
}
.interaction-window input[type=range].slider::-ms-fill-upper {
  background: #007ea7;
  border: 3px solid #003459;
  border-radius: 22px;
}
.interaction-window input[type=range].slider::-ms-thumb {
  width: 10px;
  height: 15px;
  background: #A68200;
  border: 3.1px solid #F7D91A;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
.interaction-window input[type=range].slider:focus::-ms-fill-lower {
  background: #A68200;
}
.interaction-window input[type=range].slider:focus::-ms-fill-upper {
  background: #A68200;
}
@supports (-ms-ime-align: auto) {
  .interaction-window {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  }
  .interaction-window input[type=range].slider {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
.interaction-window .num-box {
  font-weight: bold;
  font-size: 1em;
  border-style: solid;
  border-width: 0.15em;
  padding: 0.5em;
  margin: 0.5em;
  margin-bottom: 2em;
  border-radius: 0.25em;
  transition: 0.25s ease;
  box-shadow: 0px 0px 10px 0px #00a8e8;
  background-color: #00a8e8;
  border-color: #007ea7;
  filter: saturate(90%);
  color: white !important;
  min-width: 1.5em;
  width: 90%;
  max-width: 40em;
}
.interaction-window hr {
  opacity: 0.5;
  color: white;
  margin-bottom: 2.5em;
  margin-top: 0.5em;
  width: 100%;
}
.interaction-window .details {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.interaction-window .details p:first-child {
  text-align: left;
  font-weight: bold;
  margin: 0.5em;
}
.interaction-window .details p:last-child {
  text-align: right;
  font-weight: bold;
  margin: 0.5em;
}
.interaction-window .option-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0.5em;
  width: 100%;
  gap: 4em;
  margin-bottom: 0.5em;
}
.interaction-window .option-select button:disabled {
  font-weight: bold;
  border-style: solid;
  border-width: 0.15em;
  border-radius: 0.25em;
  transition: 0.25s ease;
  color: #00a8e8 !important;
  background-color: #F7D91A;
  border-color: #A68200;
  box-shadow: 0px 0px 19px 0px #F7D91A;
  filter: saturate(1.2);
  transform: scale(1.2);
  cursor: default;
}
.interaction-window button {
  font-weight: bold;
  font-size: 1.25em;
  border-style: solid;
  border-width: 0.15em;
  color: #F7D91A !important;
  min-width: 7.5em;
  padding: 0.25em;
  border-radius: 0.25em;
  transition: 0.25s ease;
  background-color: #00a8e8;
  border-color: #007ea7;
  box-shadow: 0px 0px 10px 0px #00a8e8;
  cursor: pointer;
}
.interaction-window button:hover:enabled {
  color: white !important;
  transition: 0.15s ease;
  transform: scale(1.1);
  color: #00a8e8 !important;
  background-color: #F7D91A;
  border-color: #A68200;
  box-shadow: 0px 0px 19px 0px #F7D91A;
}
.interaction-window button:disabled {
  font-weight: bold;
  border-style: solid;
  border-width: 0.15em;
  color: white !important;
  border-radius: 0.25em;
  transition: 0.25s ease;
  filter: grayscale(70%) saturate(25%);
  transform: scale(1);
  cursor: default;
}
@media only screen and (max-width: 800px) {
  .interaction-window .option-select {
    flex-direction: column;
    gap: 1em;
  }
  .interaction-window .option-select button {
    width: 70%;
  }
}/*# sourceMappingURL=main.css.map */